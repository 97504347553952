import React from 'react';
import { Button } from 'react-bootstrap';
// import colors from '../utils/colors';

function Bubble({ bubbleSize, text, onPress, bubbleColor }) {
    return (
        <Button style={{ backgroundColor: 'transparent', borderWidth: 0, borderRadius: '50%', padding: 0 }} onClick={() => {
            // alert("Button with text: " + text)
            onPress(text)
        }}>
            <div style={{ backgroundColor: bubbleColor, width: bubbleSize, height: bubbleSize }} className={"optionContainer"} >
                {/* {bubbleSize ? ( */}
                <p className="optionText">
                    {text}
                    {/* Size:
                    <br />
                    {Math.round(bubbleSize)}
                 */}
                </p>
                {/* // ) : null} */}
            </div>
        </Button>

    );
}

export default Bubble;