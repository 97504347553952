import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/performance";

const firebaseConfig = {
    apiKey: "AIzaSyA65URLp2HNdWfwv2Ns4joJ1EaHH81IuQA",
    authDomain: "decider-a1868.firebaseapp.com",
    databaseURL: "https://decider-a1868.firebaseio.com",
    projectId: "decider-a1868",
    storageBucket: "decider-a1868.appspot.com",
    messagingSenderId: "228323995744",
    appId: "1:228323995744:web:4e3cf3994faa56f7cd9305"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const perf = firebase.performance();

export { db, perf };