import axios from "axios";

function getLocationInfoFromIp() {
    return new Promise((res, rej) => {
        axios.get('https://geolocation-db.com/json/').then((response) => {
            res(response.data)
        }).catch((e) => {
            rej(e)
        })
    })
}

export default { getLocationInfoFromIp }