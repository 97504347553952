import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form, Modal, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import { ReactComponent as Logo } from '../assets/decider-logo.svg';

import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import Bubble from '../components/Bubble';

// import { db } from '../utils/firebase';
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";

import LocationService from '../utils/services/LocationService';
import FirestoreService from '../utils/services/FirestoreService';

import colors from '../utils/colors';
// import NoContent from '../components/NoContent';

// import 'bootstrap/dist/css/bootstrap.css';

function Home(props) {

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    const [show, setShow] = useState(true);
    const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
    const [showHIWDialogue, setShowHIWDialogue] = useState(false);
    const [showDisclaimerDialogue, setShowDisclaimerDialogue] = useState(false);
    const [question, setQuestion] = useState('');

    const [currentOptionValue, setCurrentOptionValue] = useState('');
    const [options, setOptions] = useState([]);
    // const [locationData, setLocationData] = useState({});

    const [decidedOption, setDecidedOption] = useState('');
    // const [iPInfo, setIPInfo] = useState('');
    const [loading, setLoading] = useState(false);

    const [currentSelectedOptionValue, setCurrentSelectedOptionValue] = useState('');

    // const [optionsFieldTextCount, setOptionsFieldTextCount] = useState(0);

    const maxLengthOfOptionString = 90;

    // onClick={() => {
    //     setShow(false);
    // }}

    useEffect(() => {
        setShow(true);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const bubbleOptions = {
        size: 180,
        // minSize: 50,
        gutter: 8,
        provideProps: true,
        numCols: 8,
        fringeWidth: 160,
        yRadius: 130,
        xRadius: 220,
        cornerRadius: 50,
        showGuides: false,
        compact: true,
        gravitation: 5
    }

    function isInArray(value, array) {
        return array.indexOf(value) > -1;
    }

    const handleQuestionSubmit = (e) => {
        e.preventDefault();
        const { questionTitle } = e.target.elements;
        if (questionTitle.value !== '') {
            setShow(false)
            setQuestion(question)
        } else {
            alert("Please add what are you deciding")
        }
    }

    const handleClose = () => {
        setShowDeleteDialogue(false);
        setShowHIWDialogue(false);
        setShowDisclaimerDialogue(false);
    };

    function addOption() {
        if (currentOptionValue !== '') {
            if (isInArray(currentOptionValue, options)) {
                //already in the options
                alert((currentOptionValue ? currentOptionValue : "This") + " option is already added.");
            } else {
                setOptions([...options, currentOptionValue]);
                setCurrentOptionValue('')
            }
        }
    }

    function addDataToFireStore() {

        const decidedOption = options[Math.floor(Math.random() * options.length)]
        // console.log("THE WINNER IS: " + decidedOption)
        var decicderData = {
            "options": options,
            "question": question,
            "decidedOption": decidedOption,
        }

        var locationData = {}

        // //Call to get IP Info
        LocationService.getLocationInfoFromIp().then((locData) => {
            //Upon Success store everything in firestore
            // addDataToFireStore(locationData);
            // setLocationData(locData);
            locationData = locData;
        }).catch((e) => {
            console.log("No IP info found. Moving on...");
        }).finally(() => {
            FirestoreService.addDecisionToFirestore(locationData, decicderData).then((res) => {
                //Update state
                setLoading(false);
                setDecidedOption(decidedOption)
            }).catch((e) => {
                //We'll just report this error
                setLoading(false);
                setDecidedOption(decidedOption)
            })
        })
    }

    // const bubbleSize = 180

    return (
        <Container fluid>

            {/* Modal START */}

            <Modal size="lg" show={show} autoFocus={true} centered={true} onHide={() => setShow(true)} aria-labelledby="example-modal-sizes-title-lg" >
                <Modal.Header style={{ justifyContent: 'space-around' }}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Decide Anything!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleQuestionSubmit}>
                        <Form.Group className="sticky-top alert" controlId="questionTitle" >
                            <Form.Label className="text-center" style={{ width: "100%" }}>What are you deciding?</Form.Label>
                            <Form.Control autoFocus required value={question} onChange={(e) => {
                                setQuestion(e.target.value);
                            }}
                                size="lg" type="text" className="text-center" placeholder="Where to go for a trip? What to eat? Who should get the last slice?..." style={{ borderRadius: 100 }} />
                            <Button type="submit" style={{ borderRadius: 100, width: '100%', backgroundColor: colors.primaryOrange, borderWidth: 0, marginTop: 8, height: 60, fontWeight: 'bold', fontSize: 20 }}>START</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-around' }}>
                    <Form.Text className="text-muted" style={{ textAlign: 'center' }}>
                        The goal of decider is to help you get to a decision quickly. Write what you're deciding then add as many options as you want and the decider will randomly pick one for you without any bias or logic.
                    </Form.Text>
                </Modal.Footer>
            </Modal>

            {/* Modal END */}

            {/* Delete Option MODAL START */}
            <Modal show={showDeleteDialogue}>
                <Modal.Header>
                    <Modal.Title>Delete {(currentSelectedOptionValue !== '') ? currentSelectedOptionValue : "Option?"}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Are you sure you want to delete {((currentSelectedOptionValue !== '') ? currentSelectedOptionValue : 'this')} option.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShowDeleteDialogue(false);
                        setCurrentSelectedOptionValue('');
                    }}>No, Cancel</Button>
                    <Button variant="danger" onClick={() => {
                        options.splice(options.indexOf(currentSelectedOptionValue), 1);
                        setShowDeleteDialogue(false);
                        setCurrentSelectedOptionValue('');
                    }}>Yes, Delete</Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Option MODAL END */}

            {/* How tt works MODAL START */}
            <Modal show={showHIWDialogue} onHide={handleClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title>How it works?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Decider helps you take everyday small decisions quickly. We encourage our users to make a rule, whatever comesup on decider you'll go with it. Doing so will help you get to a decision quickly and since our algorithm is completely random and non-biased. Our studies show that  users tend who ted to go with the decision that decider made for them ends up happy.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Okay
                    </Button>
                    {/* <Button variant="primary">Understood</Button> */}
                </Modal.Footer>
            </Modal>
            {/* How tt works MODAL END */}

            {/* DISCLAIMER MODAL START */}
            <Modal
                show={showDisclaimerDialogue}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Decider is a fun to use app and decision are made on a random and unbaised manner. We don't encourage taking like decisions with it nor anything that might end up in harrming yourself or others. Use it for the fun of it and please get help if you are facing any such thoughts.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Okay
                    </Button>
                    {/* <Button variant="primary">Understood</Button> */}
                </Modal.Footer>
            </Modal>
            {/* DISCLAIMER MODAL END */}

            <Row >
                <Col className='bubbleContainer'>
                    {/* Header START */}
                    <Col className="header">
                        <div>
                            <Logo />
                        </div>
                        <h3 style={{ fontWeight: 'bold', color: colors.secondaryBlack }}>{(question !== '') ? question : 'Everyday decisions made easy!'}</h3>
                        {(options.length <= 0 && !show) && <p style={{ color: colors.secondaryBlack }}>Start adding options below</p>}
                        {loading && <Spinner animation="grow" />}

                        {/* {(options.length <= 0) && <div className='col-lg-12 col-xs-12 noContent'>
                            <hr />
                            <h1 style={{ color: colors.primaryOrange, marginTop: 50 }}>Start adding options</h1>
                        </div>} */}

                    </Col>
                    {/* Header END */}


                    {/* Bubbles Container START */}
                    <BubbleUI options={bubbleOptions} className="myBubbleUI" style={{ height: height }}>
                        {options.map((option, index) => (
                            <Bubble className="child" bubbleSize={180}
                                bubbleColor={(decidedOption === option) ? colors.selectedGreen : (decidedOption === '') ? colors.primaryOrange : colors.lightGrayText}
                                text={option} key={index} onPress={(decidedOption === '') ? (val) => { setCurrentSelectedOptionValue(val); setShowDeleteDialogue(true); } : null} />
                        ))}
                    </BubbleUI>

                    {/* Bubbles Container END */}
                    {/* Bottom View START */}

                    <div className='col-lg-12 col-xs-12' style={{ backgroundColor: colors.bgLightGray, position: 'absolute', bottom: 0, left: 0, right: 0, padding: 10 }}>
                        <InputGroup>
                            <FormControl autoFocus type="text" disabled={(decidedOption === '') ? false : true} placeholder="Enter option"
                                className='col-lg-4' value={currentOptionValue} maxLength={maxLengthOfOptionString}
                                style={{ borderRadius: 100, borderWidth: 1, borderColor: colors.border, height: 60, marginRight: 16, padding: 24 }}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        addOption()
                                    }
                                }}
                                onChange={(e) => {
                                    setCurrentOptionValue(e.target.value)
                                }} />
                            <Button type="submit" style={{ borderRadius: 100, backgroundColor: (decidedOption === '') ? colors.primaryOrange : colors.lightGrayText, borderWidth: 0, height: 60, fontWeight: 'bold', fontSize: 20 }}
                                onClick={(decidedOption === '') ? () => { addOption() } : null}>Add</Button>
                        </InputGroup>
                        {/* <Row> */}

                        {(options.length >= 3) &&
                            <Button style={{ backgroundColor: (decidedOption === '') ? '#DC143C' : colors.primaryOrange, borderWidth: 0, marginTop: 8, width: '100%', borderRadius: 100, height: 40 }} onClick={() => {
                                if (decidedOption === '') {

                                    setLoading(true);
                                    addDataToFireStore();

                                } else {
                                    //Take a new decision
                                    setLoading(false);
                                    window.location.reload(true);
                                }
                            }}>{(decidedOption === '') ? "DECIDE" : "TAKE A NEW DECISION"}</Button>}

                        <p className='charactersLeftText' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>{currentOptionValue.length}/{maxLengthOfOptionString} characters left - Total options added: {options.length}  (Minimum 3 options required to make a decision)</p>

                        {(decidedOption !== '') && <p>Decider has picked: <span style={{ fontWeight: 'bold', color: colors.selectedGreen }}>{decidedOption}</span></p>}


                        {/* <a className='hyperlink' style={{ fontWeight: 'bold' }} onClick={() => {
                                // alert("yes")
                                window.location.reload(true);
                            }}>
                                TAKE A NEW DECISION
                            </a> */}

                        {/* </Row> */}
                        <hr />
                        <p className='copyrightText'><a onClick={() => { setShowHIWDialogue(true) }} className='hyperlink'>How it works</a> | <a className='hyperlink' href="https://forms.gle/zLsUAfggaKRJvddD9" target="_blank">Give Feedback</a> | <a className='hyperlink' onClick={() => { setShowDisclaimerDialogue(true) }}>Disclaimer</a>
                            <br />Decider v1.0.2 | © 2022 ibjects.com</p>
                    </div>

                    {/* <Form className='col-lg-12 col-xs-12' style={{ backgroundColor: colors.bgLightGray, position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                        <Form.Group className="sticky-bottom alert col-lg-6" controlId="optionAdded">
                            <Form.Control type="text" placeholder="Enter option" style={{ borderRadius: 100, borderWidth: 0, height: 60, width: '100%' }} />
                            <Button type="submit" style={{ borderRadius: 100, backgroundColor: colors.primaryOrange, borderWidth: 0, marginTop: 8, fontWeight: 'bold', fontSize: 20 }}>Add</Button>
                        </Form.Group>
                    </Form> */}

                    {/* Bottom View END */}

                </Col>
            </Row>

        </Container>
    );
}

export default Home;