import { db } from '../firebase';

function addDecisionToFirestore(locationData, decisionData) {

    // console.log("LOC DATA: " + JSON.stringify(locationData))

    return new Promise((res, rej) => {

        const data = {
            "isBestOf3": false,
            "options": decisionData.options,
            "question": decisionData.question,
            "source": "web",
            "decidedOption": decisionData.decidedOption,
            "country_code": locationData.country_code ? locationData.country_code : "ERR_BLOCKED_BY_CLIENT",
            "country_name": locationData.country_name ? locationData.country_name : "ERR_BLOCKED_BY_CLIENT",
            "latitude": locationData.latitude ? locationData.latitude : "ERR_BLOCKED_BY_CLIENT",
            "longitude": locationData.longitude ? locationData.longitude : "ERR_BLOCKED_BY_CLIENT",
            "IPv4": locationData.IPv4 ? locationData.IPv4 : "ERR_BLOCKED_BY_CLIENT",
        }

        // console.log("DATA IS: " + JSON.stringify(data));

        db.collection("Decisions").add(data).then((docRef) => {
            res(docRef);
        }).catch((e) => {
            rej(e);
        })
    })
}

export default { addDecisionToFirestore }